.activeProfileItems {
  border: 1px solid #04a9fe;
  background-color: #f1f1f1;
  margin: 0px;
  margin-bottom: 10px;
  padding: 5px 8px;
  cursor: pointer;
}

.inactiveProfileItems {
  border: 1px solid gray;
  margin: 0px;
  margin-bottom: 10px;
  padding: 5px 8px;
  cursor: pointer;
}

.switchPillsTabs label {
  font-weight: 600;
}

.modaldata span {
  display: inline-block;
  font-weight: 500;
  padding-right: 5px;
  font-size: 14px;
}
/* .modaldata div {
  display: flex;
  font-weight: 600;
  color: aqua;
} */

/* Raise Ticket CSS */

.raise_ticket_history_section {
  background-color: #f1f3f6;
  padding: 14px 0px 14px 0px;
}

.raise_ticket_history_main_div {
  background-color: var(--white-color);
  border-radius: 4px;
  position: relative;
  max-height: 460px;
  overflow: auto;
}

.raise_ticket_username_main_div > h2 {
  font-size: 20px;
  text-transform: capitalize;
  color: var(--primary-color);
  font-weight: 700;
  margin-bottom: 3px !important;
}

.raise_ticket_username_main_div > p {
  font-size: 14px;
  font-weight: 500;
}

.raise_ticket_username_main_div > p > b {
  color: var(--six-color);
  font-size: 15px;
}

.raise_histroy_user_flex_div {
  display: flex;
  justify-content: space-between;
  /* position: sticky;
  top: 0; */
  padding: 10px 10px 10px 10px;
  background-color: var(--gray-color4);
  margin-bottom: 15px;
  border-bottom: 1px dashed var(--gray-color2);
}

.raise_histroy_user_flex_div > a {
  color: var(--six-color) !important;
  text-decoration: underline !important;
  font-size: 14px;
  font-weight: 600;
}

.raise_ticket_history_details_div {
  padding: 0px 10px 10px 10px;
}

.raise_ticket_history_details_div > h1 {
  font-size: 20px;
  text-transform: capitalize;
  color: var(--black-color3);
  font-weight: 700;
  margin-bottom: 3px !important;
}

.raise_ticket_history_details_div > p {
  font-size: 15px;
  color: var(--black-color5);
  font-weight: 500;
}

.raise_ticket_history_date_time_div {
  text-align: left;
  margin-top: 6px;
}

.raise_ticket_history_date_time_div > p > strong {
  font-weight: 600;
  color: var(--black-color4);
  font-size: 14px;
}

.raise_ticket_history_img_div > img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border: 1px solid var(--gray-color3);
  border-radius: 4px;
  margin-top: 10px;
  display: block;
}

.raise_history_answered_details_div {
  padding: 0px 10px 10px 10px;
  text-align: right;
}

.raise_history_answered_details_div > h5 {
  color: var(--rating-color2);
  margin-bottom: 0px !important;
  font-size: 17px;
  font-weight: 700;
  text-transform: capitalize;
}

.raise_history_answered_details_div > p {
  font-size: 15px;
  color: var(--black-color5);
  font-weight: 500;
}
.raise_ticket_hsitory_answered_img_div {
  text-align: right;
  margin-left: auto;
}

.raise_ticket_hsitory_answered_img_div > img {
  height: 150px;
  width: 150px;
  object-fit: contain;
  border: 1px solid var(--gray-color3);
  border-radius: 4px;
  margin-top: 10px;
  display: inline-block;
  margin-right: 0;
}

.raise_ticket_history_answered_date_time_div {
  text-align: right;
  margin-top: 6px;
}

.raise_ticket_history_answered_date_time_div > p > strong {
  font-weight: 600;
  color: var(--black-color4);
  font-size: 14px;
}

/* width */
.raise_ticket_history_main_div::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.raise_ticket_history_main_div:hover::-webkit-scrollbar-track {
  background-color: var(--white-color);
}

/* Handle */
.raise_ticket_history_main_div:hover::-webkit-scrollbar-thumb {
  background-color: var(--third-color);
  border-radius: 100px;
}

/* Handle on hover */
.raise_ticket_history_main_div:hover::-webkit-scrollbar-thumb:hover {
  background: var(--six-color) !important;
}
.cursor-pointer{
  cursor: pointer !important;
}
.user_profile_section{
    background-color: #f1f3f6;
    padding: 35px 0px 35px 0px;
}

.userprofile_main_div_relative{
    background-color: var(--white-color);
    border-radius: 6px;
}

.userprofile_flex_main_div{
    display: flex;
    gap: 20px;
     
}

.userprofile_image_main_div{
    background-color: var(--six-color);
    padding: 30px 30px;
}

.userprofile_image_main_div > .user_image_box{
    text-align: center;
    margin-bottom: 20px;
}

.userprofile_image_main_div > .user_image_box > img{
    width: 90px;
    height: 90px;
    border-radius: 100%;
    object-fit: cover;
}

.user_image_box > h2{
    color: var(--white-color);
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    margin-top: 15px;
    margin-bottom: 10px !important;
}


.user_image_box > h5{
    color: var(--gray-color3);
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 2px;
}

.userprofile_edit_btn_div{
    margin-top: 15px;
    background-color: var(--white-color);
    padding: 7px 10px 4px 10px;
    border-radius: 3px;
}

.userprofile_edit_btn_div > a{
    color: var(--six-color) !important;
    font-weight: 600;
}

.user_profile_details_flex_table{
    width: 70%;
    text-align: right;
}

.user_profile_table_row{
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    border-bottom: 1px dashed var(--gray-color3);
    padding: 15px 10px;
    
}

.user_profile_table_row > h2{
    color: var(--black-color2);
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0px !important;
}


.user_profile_table_row > h3{
    color: var(--black-color5);
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    margin-bottom: 0px !important;
}
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Josefin Sans", serif;
}

body {
  font-family: "Josefin Sans", serif;
  overflow-x: hidden !important;
}

table {
  font-size: 12px !important;
}

:root {
  --primary-color: #0087cb;
  --secondry-color: #49b3dd;
  --third-color: #50bae3;
  --four-color: #fff0d3;
  --five-color: #e46800;
  --six-color: #045b87;
  --white-color: #fff;
  --black-color: #000;
  --black-color2: #141615;
  --black-color3: #202020;
  --black-color4: #212529;
  --black-color5: #424040;
  --black-color6: #636363;
  --danger-color: #c4302b;
  --lightgray-color: #e7e6e6;
  --rating-color: #e5aa0d;
  --rating-color2: #388e3c;
  --gray-color: #a1a1a1;
  --gray-color2: #a5a4a4;
  --gray-color3: #e7e7e7;
  --gray-color4: #f2f2f2;
  --gray-color5: #717478;
  --border-color: #f8f0d3;
  --facebook-color: #3b5998;
  --instagram-color: #962fbf;
  --linkedin-color: #0a66c2;
  --youtube-color: #c4302b;
  --whatsapp-color: #18851a;
  --twitter-color: #000;
  --pinterest-color: #e60023;
  --background-color: #fafafa;
  --footer-color: #f0f0f0;
  --footer-color2: #f9f3f0;

  --card_radius_1: 0.25rem;
  --card_radius_2: 0.5rem;
  --card_radius_3: 0.75rem;
  --card_radius_3: 1rem;

  --primary-padding: 0.25rem;
  --primary-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  --border-radius: 0.75rem;

  --icon-bg: var(--primary-color);
  --icon-color: var(--white-color);
  --text-color: var(--black-color2);
  --hover-color: var(--primary-color);
  --divider-color: var(--gray-color3);
  --badge-size: 20px;
}

.dashboard_cards {
  border-radius: var(--border-radius);
  padding: 1rem;
  border-radius: 5px;
  background-color: var(--white-color);
  border: 1px solid var(--gray-color3);
}

.dashboard_cards a {
  color: var(--black-color5) !important;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
}

.dashboard_cards .dashboard_cards_icon {
  background-color: var(--gray-color4);
  border: 1px solid var(--gray-color4);
  border-radius: 50%;
  font-size: 40px;
  font-weight: 800;
  color: var(--six-color);
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
}

.dashboard_cards h6{
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--black-color3);
  margin-bottom: 5px !important;
}

col {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

.row {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

.Form_Field {
  margin-bottom: 10px;
}

.Form_Field_Heading {
  font-weight: 600;
  font-size: 14px;
}

.Form_Field_Heading span {
  color: red;
  padding: 0px 5px;
}


.Form_Field_Submit_Button button {
  outline: none;
  border: none !important;
  border-radius: 2px;
  padding: 5px 20px;
  background-color: var(--six-color) !important;
  color: var(--white-color) !important;
  font-size: 13px !important;
}

.Form_Field_Input {
  outline: none;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 5px 10px;
  width: 100%;
}

.Form_Field_Input_Array {
  outline: none;
  border: 1px solid lightgray !important;
  border-radius: 2px !important;
  padding: 5px 10px;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: none !important;
  outline: none !important;
}

.errormsg{
  font-size: 14px !important;
}

.is-active {
  background-color: #007bff;
  color: white;
}

.testimonials_table{
  overflow: auto !important;
}


.status-text{
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
}

.testimonials_table .approve{
  background-color: var(--rating-color2) !important;
  color: var(--white-color);
  border-radius: 4px !important;
  font-size: 13px;
}


.testimonials_table .pending{
  background-color: var(--rating-color) !important;
  color: var(--white-color);
  border-radius: 4px !important;
  font-size: 13px;
}


.testimonials_table .rejected{
  background-color: var(--danger-color) !important;
  color: var(--white-color);
  border-radius: 4px !important;
  font-size: 13px;
}
/* order mgmt css start */
.title_search_div h4{
color: var(--six-color);
font-size: 15px !important;
font-weight: 500;
text-transform: capitalize;
}

.search_form_div{
  margin-bottom: 20px;
}

.search_form_div input{
  border-radius: 4px 0px 0px 4px!important;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid var(--gray-color3) !important;
  font-size: 14px !important;
  color: var(--black-color4) !important;
}


.search_form_div ::placeholder{
  font-size: 13px !important;
}

.search_form_div button{
  background-color: var(--six-color) !important;
  border-radius: 0px 4px 4px 0px !important;
  border: 1px solid var(--six-color) !important;
  box-shadow: none !important;
  font-size: 14px !important;
  letter-spacing: .3px;
}


.admin_order_mgt_title_div h2{
  color: var(--black-color4);
font-size: 18px;
font-weight: 500;
text-transform: capitalize;
margin-bottom: 6px !important;
}

.orders_table_div{
  overflow-x: auto !important;
  height: 65vh;
  overflow-y: auto !important;
}


 /* width */
.orders_table_div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  overflow: hidden;
}

/* Track */
  .orders_table_div::-webkit-scrollbar-track {
  background: var(--white-color);
  border-radius: 0px;

}

/* Handle */
  .orders_table_div::-webkit-scrollbar-thumb {
  background: var(--primary-color); 
  border-radius: 0px;
}

/* Handle on hover */
  .orders_table_div::-webkit-scrollbar-thumb {
  background: var(--primary-color); 
}

/*  */

/* width */
.table_user_data::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  overflow: hidden;
}

/* Track */
  .table_user_data::-webkit-scrollbar-track {
  background: var(--white-color);
  border-radius: 0px;
}

/* Handle */
  .table_user_data::-webkit-scrollbar-thumb {
  background: var(--primary-color); 
  border-radius: 0px;
}

/* Handle on hover */
  .table_user_data::-webkit-scrollbar-thumb {
  background: var(--primary-color); 
}

/*  */

.banner_status {
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  color: var(--white-color);
}

.orders_table_div > table > thead{
  background-color: var(--gray-color3) !important;
}

.orders_table_div > table > thead > tr > th{
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid var(--white-color) !important;
  outline: none !important;
  vertical-align: middle;
  align-items: center;
  padding: 10px 8px !important;
  color: var(--black-color3) !important;
  background-color: var(--gray-color3) !important;
}

.testimonials_table > table > thead > tr > th{
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid var(--white-color) !important;
  outline: none !important;
  vertical-align: middle;
  align-items: center;
  padding: 10px 8px !important;
  color: var(--black-color3) !important;
  background-color: var(--gray-color3) !important;
}


.testimonials_table > table > tbody > tr > th{
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid var(--white-color) !important;
  outline: none !important;
  vertical-align: middle;
  align-items: center;
  padding: 10px 8px !important;
  color: var(--black-color3) !important;
  background-color: var(--gray-color3) !important;
}

.testimonials_table > table > tbody > tr > td > .bg-warning{
background-color: var(--rating-color) !important;
padding: 5px 6px !important;
color: var(--white-color) !important;
font-size: 12px;
font-weight: 600;
text-transform: capitalize;
border-radius: 3px !important;
cursor: pointer;
}

.testimonials_table > table > tbody > tr > td > svg{
  margin: 0px 0px 0px 5px !important;
 font-size: 17px !important;
}

.close_ticket_btn{
  background-color: var(--danger-color) !important;
  padding: 5px 6px !important;
  color: var(--white-color) !important;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 3px !important;
  cursor: pointer;
  }


.testimonials_table > table > tbody > tr > td{
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  border: 1px solid var(--gray-color3) !important;
  outline: none !important;
  align-items: center;
  padding: 10px 8px !important;
  color: var(--black-color3) !important;
  background-color: var(--white-color) !important;  
}

.testimonials_table > table > tbody > tr > td > img{
  object-fit: contain;
}


.orders_table_div > table > tbody > tr > td{
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  border: 1px solid var(--gray-color3) !important;
  outline: none !important;
  align-items: center;
  padding: 10px 8px !important;
  color: var(--black-color3) !important;

}



.orders_table_div > table > tbody > tr > td > div{
  margin-bottom: 10px;
  font-size: 12px;

}

.orders_table_div > table > tbody > tr > td > div > b{
  color: var(--black-color);
  font-weight: 600;
  font-size: 12px;
}


.orders_table_div > table > tbody > tr > td > ul{
  list-style: none !important;
  padding: 0px 1px !important;
}

.orders_table_div > table > tbody > tr > td > ul > li > div{
 margin-bottom: 7px !important;
}

.orders_table_div > table > tbody > tr > td > ul > li > div > strong{

    color: var(--black-color);
    font-weight: 600;
    font-size: 12px;

}

.orders_table_div > table > tbody > tr > .paid_btn_td > .dropdown > button{
  background-color: var(--rating-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px 5px 5px!important;
}


.orders_table_div > table > tbody > tr > .paid_btn_td button{
  background-color: var(--six-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px !important;
  color: var(--white-color) !important;
  width: 120px !important;
}



.orders_table_div > table > tbody > tr > .notpaid_btn_td > .dropdown > button{
  background-color: var(--danger-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px 5px 5px!important;
}


.orders_table_div > table > tbody > tr > .notpaid_btn_td button{
  background-color: var(--six-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px !important;
  color: var(--white-color) !important;
  width: 120px !important;
}

.orders_table_div > table > tbody > tr > .cancelorder_btn > .dropdown > .dropdown-menu{
  width: 250px !important;
background-color: var(--white-color) !important;
color: var(--black-color) !important;

}

.orders_table_div > table > tbody > tr > .cancelorder_btn > .dropdown > button{
  background-color: var(--danger-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px 5px 5px!important;
}


.orders_table_div > table > tbody > tr > .cancelorder_btn button{
  background-color: var(--six-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px !important;
  color: var(--white-color) !important;
  width: 250px !important;
}


.orders_table_div > table > tbody > tr > .notcancelorder_btn > .dropdown > .dropdown-menu{
  width: 250px !important;
background-color: var(--white-color) !important;
color: var(--black-color) !important;


}

.orders_table_div > table > tbody > tr > .notcancelorder_btn > .dropdown > button{
  background-color: var(--primary-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px 5px 5px!important;
}

.orders_table_div > table > tbody > tr > .notcancelorder_btn button{
  background-color: var(--six-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px !important;
  color: var(--white-color) !important;
  width: 250px !important;
}



.orders_table_div > table > tbody > tr > .notdeliver_btn > .dropdown > button{
  background-color: var(--danger-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px 5px 5px!important;
  width: 150px;
}


.orders_table_div > table > tbody > tr > .delivered-btn > .dropdown > button{
  background-color: var(--rating-color2) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px 5px 5px!important;
  width: 150px;
}




.orders_table_div > table > tbody > tr > td > .dropdown > .dropdown-menu{
  padding: 0px !important;
  border-radius: 3px !important;
  border: 1px solid var(--gray-color4) !important;
background-color: var(--gray-color4) !important;
}

.orders_table_div > table > tbody > tr > td > .dropdown > .dropdown-menu > .dropdown-item{
padding: 5px 6px !important;
border-bottom: 1px solid var(--gray-color3) !important;
font-size: 13px !important;
font-weight: 400;
background-color: var(--white-color) !important;
color: var(--black-color) !important;
}

.orders_table_div > table > tbody > tr > td > select{
  border-radius: 4px 0px 0px 4px!important;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid var(--gray-color3) !important;
  font-size: 13px !important;
  color: var(--black-color4) !important;
  font-weight: 500;
  padding: 6px 10px 6px 1px!important;
}

.orders_table_div > table > tbody > tr > td > .invoice_btn{
  background-color: var(--six-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 13px;
  padding: 5px 10px !important;
  color: var(--white-color) !important;
  width: 120px;
}


.refund_td_btn > b{
  background-color: var(--rating-color2) !important;
  padding: 4px 10px;
  border-radius: 3px;
  color: var(--white-color);
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  width: 120px !important;
  display: inline-block;
  text-align: center;
}


.refund_cod_btn > b{
  background-color: var(--rating-color) !important;
  padding: 4px 10px;
  border-radius: 3px;
  color: var(--white-color);
  font-size: 13px;
  font-weight: 500;
  cursor: default;
  width: 120px !important;
  display: inline-block;
  text-align: center;
}


/* order mgmt css end */

/*  */
.homepage_banner_title_div > h1{
font-size: 18px;
margin-bottom: 15px;
text-transform: capitalize;
color: var(--six-color);
font-weight: 600;
}


.Form_Field_Heading{
  font-size: 13px;
  color: var(--black-color5);
  margin-bottom: 4px;
  font-weight: 500;
}

.Form_Field_Input{
  background-color: var(--white-color) !important;
  border-radius: 3px !important;
  border: 1px solid var(--gray-color3) !important;
  box-shadow: none !important;
  font-size: 13px !important;

}

.remove_btn{
  background-color: var(--danger-color);
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  border: none !important;
  outline: none !important;
  padding: 5px 15px;
  margin-top: 6px;
  border-radius: 3px;
  margin-bottom: 10px;
}


.add_btn{
  background-color: var(--whatsapp-color);
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  border: none !important;
  outline: none !important;
  padding: 5px 15px;
  margin-top: 12px;
  border-radius: 3px;
}

::placeholder{
  font-size: 13px !important;
}

.table_user_data{
  overflow-x: auto !important;
  margin-top: 10px !important;
}

.table_user_data > table > thead > tr > th{
font-size: 13px !important;
color: var(--black-color4) !important;
font-weight: 600;
}

.add_specification{
  background-color: var(--six-color);
  color: var(--white-color) !important;
  text-decoration: none !important;
  cursor: pointer;
  padding: 6px 10px !important;
  border-radius: 4px;
}


.products_review{
  background-color: var(--rating-color);
  color: var(--white-color) !important;
  text-decoration: none !important;
  cursor: pointer;
  padding: 6px 10px !important;
  border-radius: 4px;
  font-weight: 500;
}


.table_user_data > table > tbody > tr > td{
  font-size: 13px !important;
  color: var(--black-color4) !important;
  font-weight: 400;
}


.table_user_data > table > tbody > tr > td > button{
  background-color: var(--six-color) !important;
  font-size: 13px; 
  border: none !important;
  border-radius: 3px !important;
}

.table_user_data > table > tbody > tr > td > div{
  margin-bottom: 7px;
}

.table_user_data > table > tbody > tr > td > div {
  font-size: 13px;
  color: var(--black-color4);
}

.table_user_data > table > tbody > tr > td > div > strong{
  font-size: 13px;
  font-weight: 600;
}


.table_user_data > table > tbody > tr > td > select{
  font-size: 13px !important;
  border-radius: 0px !important;
  border: 1px solid var(--gray-color3) !important;
  box-shadow: none !important;
  outline: none !important;
  height: 30px;
}

.status-select {
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  border: none;
  
}


.table_user_data .active {
  background-color: var(--rating-color2);
}

.table_user_data .inactive {
  background-color: var(--rating-color);
}

.table_user_data .blocked {
  background-color: var(--danger-color);
}

.static_page_table{
  overflow-x: auto !important;
  margin-top: 15px !important;
}

.static_page_table > table > thead > tr > th{
font-size: 13px !important;
color: var(--black-color4) !important;
font-weight: 600;
}


.static_page_table > table > tbody > tr > td{
  font-size: 13px !important;
  color: var(--black-color4) !important;
  font-weight: 400;
}


.static_page_table > table > tbody > tr > td > button{
  background-color: var(--six-color) !important;
  font-size: 13px; 
  border: none !important;
  border-radius: 3px !important;
}

.static_page_table > table > tbody > tr > td > div{
  margin-bottom: 7px;
}

.static_page_table > table > tbody > tr > td > div {
  font-size: 13px;
  color: var(--black-color4);
}

.static_page_table > table > tbody > tr > td > div > strong{
  font-size: 13px;
  font-weight: 600;
}


.static_page_table > table > tbody > tr > td > div > h2{
  font-size: 20px;
  text-transform: capitalize;
}


.static_page_table > table > tbody > tr > td > div > h3{
  font-size: 20px;
  text-transform: capitalize;
}



.static_page_table > table > tbody > tr > td > div > h4{
  font-size: 20px;
  text-transform: capitalize;
}

.static_page_table > table > tbody > tr > td > div > p{
  text-align: left !important;
}

.approve_btn > b{
  background-color: var(--rating-color2) !important;
  color: var(--white-color) !important;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  width: 100px;
}

.action_button_update .static_edit_btn{
  background-color: var(--rating-color) !important;
  color: var(--white-color) !important;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border: none !important;
  outline: none !important;
  width: 100px;

}


.action_button_update svg{
  font-size: 18px;
}

.action_button_update{
  cursor: pointer;

}


.static_dlt_div .static_dlt_btn{
  background-color: var(--danger-color) !important;
  color: var(--white-color) !important;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border: none !important;
  outline: none !important;
  width: 100px;
}




.contact_rqst_page_table{
  overflow-x: auto !important;
  margin-top: 15px !important;
}

.contact_rqst_page_table > table > thead > tr > th{
font-size: 13px !important;
color: var(--black-color3) !important;
font-weight: 600;
background-color: var(--gray-color4);
}


.contact_rqst_page_table > table > tbody > tr > td{
  font-size: 13px !important;
  color: var(--black-color4) !important;
  font-weight: 400;
}


.contact_rqst_page_table > table > tbody > tr > td > div > strong{
  font-size: 13px;
  font-weight: 600;
}


.contact_rqst_page_table > table > tbody > tr > td > button{
  background-color: var(--six-color) !important;
  font-size: 12px; 
  border: none !important;
  border-radius: 3px !important;
  color: var(--white-color);
  padding: 4px 10px;
}

/*  */

.category_managment_title_div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.category_managment_title_div > h1{
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: var(--six-color);
  font-weight: 600;
  }

  .category_managment_title_div > button{
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
    font-size: 13px;
    font-weight: 600;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .product_description_div{
    background-color: var(--white-color);
    padding: 15px 20px;
    border-radius: 3px;
    border: 1px solid var(--gray-color3);
  }

  .Form_Field_Heading_heading{
    font-size: 14px;
    margin-bottom: 14px;
    font-weight: 700;
    text-transform: capitalize;
  }

  .Form_Field_Heading_heading span{
    color: var(--danger-color);
    
  }

  .selecet_form_fld{
    border: 1px solid var(--gray-color3) !important;
    outline: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    padding: 4px 2px !important;
    font-size: 14px !important;
  }

  .form_main_div{
    margin-top: 20px;
    background-color: var(--white-color);
    padding: 20px;
  }

  .form_main_div label{
    font-size: 13px;
    font-weight: 500;
    color: var(--black-color4);
  }

  .form_main_div input{
    border-radius: 3px !important;
    outline: none !important;
    border: 1px solid var(--gray-color3) !important;
    box-shadow: none !important;
  }

  .form_main_div input[type="datetime-local"]{
    border-radius: 3px !important;
    outline: none !important;
    border: 1px solid var(--gray-color3) !important;
    box-shadow: none !important;
    font-size: 13px !important;
  }

  

  .form_main_div input[type="file"]{
    border-radius: 3px !important;
    outline: none !important;
    border: 1px solid var(--gray-color3) !important;
    box-shadow: none !important;
    font-size: 13px !important;
  }

  input[type="file"]{
    border-radius: 3px !important;
    outline: none !important;
    border: 1px solid var(--gray-color3) !important;
    box-shadow: none !important;
    font-size: 13px !important;
  }

  .deal_btn_btn{
    background-color:var(--six-color) !important;
    border-radius: 3px !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 13px !important;
    border: none !important;
    color: var(--white-color) !important;
    padding: 6px 12px;
  }

  .contact_request_flex_div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .contact_request_flex_div .contac_pg_title h2{
    font-size: 20px;
    text-transform: capitalize;
    color: var(--six-color);
    font-weight: 700;
    margin-bottom: 3px !important;

  }

  
  .contact_request_flex_div .contac_pg_length p{
    font-size: 13px;
    text-transform: capitalize;
    color: var(--six-color);
    font-weight: 500;
    margin-bottom: 0px !important;
    background-color: var(--rating-color);
    color: var(--white-color);
    padding: 4px 7px;
    border-radius: 4px;
  }

  .update_banner_title{
    font-size: 18px;
    text-transform: capitalize;
    color: var(--six-color);
    font-weight: 600;
    margin-bottom: 20px !important;
  }

  .update_banner_main_div{
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 4px;
    border: var(--gray-color3);
  }

.modal-header{
   padding: 12px 20px !important;
  }

  .modal-title{
    font-size: 18px !important;
    color: var(--black-color) !important;
    text-transform: capitalize;
    font-weight: 700 !important;
    margin-bottom: 1px !important;
  }

/* 
.Form_Field{
    border-radius: 3px !important;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid var(--gray-color3) !important;
    font-size: 14px !important;
    color: var(--black-color4) !important;
    font-weight: 400 !important;
  } */

  ::placeholder{
    font-size: 14px !important;
    color: var(--black-color4) !important;
    font-weight: 400 !important;
  }

  .update_cate_label{
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
    color: var(--black-color);
  }

  .modal_category_input input{
    border-radius: 4px !important;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid var(--gray-color3) !important;
    font-size: 13px;
    padding: 9px 5px !important;
  }

  .modal_category_input ::placeholder{
    font-size: 13px;
  }

  .modal-footer{
    padding: 5px 20px 5px 0px!important;
    justify-content: start !important;
    border: none !important;

  }

  .modal-body{
    padding: 1px 20px !important;
  }

  .delete_btn_deals{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--danger-color) !important;
    color: var(--white-color) !important;
    padding: 6px 20px !important;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer;
  }

  .close_btn_up{
    border: 1px solid var(--danger-color) !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--white-color) !important;
    color: var(--danger-color) !important;
    padding: 5px 10px !important;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
  }

  
  .update_btn_up{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--rating-color2) !important;
    color: var(--white-color) !important;
    padding: 5px 10px !important;
    border-radius: 3px;
    font-size: 14px;
  }

  .reply_tickt_btn{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--rating-color) !important;
    color: var(--white-color) !important;
    padding: 5px 6px !important;
    border-radius: 3px;
  }

  .signle_online_title{
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--black-color3);
  }

  .single_catename{
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: var(--black-color5);
  }

  .signle_online_image{
    text-align: center;
  }

  .signle_online_image img{
    margin:auto !important;
  }


.rating_testimonial label svg{
 width: 17px !important;
}

.star-rating{
  margin-top: -10px !important;
}

.testimonial_select{
  width: 100% !important;
  border-radius: 3px !important;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid var(--gray-color3) !important;
    font-size: 14px !important;
    color: var(--black-color4) !important;
    font-weight: 400 !important;
    padding: 6px 4px !important;
}

.modal-header .btn-close{
  border: none !important;
  box-shadow: none !important;
  outline:  none !important;
  font-size: 14px !important;
}

.testimonial_txt_div{
  font-size: 14px;
  line-height: 23px;
  color: var(--black-color5) !important;
  margin: 5px 0px 10px 0px;
  text-align: justify;
}

.title_testimonial_modal{
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  color: var(--black-color3) !important;
  font-weight: 500;

 
}

.testimonilas_review_div svg{
  margin: 0px 0px 0px 5px;
  font-size: 17px;
}

/* nav tabs css start */

.navtabs_main_div > nav{
  padding: 0px !important;
  margin: 0px !important;
}

.navtabs_main_div > nav > .nav-tabs{
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  margin-bottom: 10px;
  background-color: var(--gray-color3) !important;
  margin-top: 10px !important;
}

.navtabs_main_div > nav > .nav-tabs > button{
  border-radius: 0px !important;
  background-color: var(--primary-color);
  color: var(--white-color) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 10px 25px 10px 25px!important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  width: 50%;
}

.navtabs_main_div > nav > .nav-tabs > button.active{
  border-radius: 0px !important;
  background-color: var(--six-color);
  color: var(--white-color) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 10px 25px 10px 25px!important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
/* nav tabs css end */

.refund_from_div label{
font-size: 13px;
font-weight: 500;
text-transform: capitalize;
margin-bottom: 4px !important;
}

.refund_from_div input{
  border: 1px solid var(--gray-color3) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  margin-bottom: 6px !important;

}

.refund_from_div ::placeholder{
  font-size: 13px !important;
}

.footer_modal{
padding: 6px 20px 10px 20px !important;
}

.footer_modal button.btn-secondary{
  border-radius: 5px !important;
  outline: none !important;
  border: 1px solid var(--primary-color) !important;
  box-shadow: none !important;
  font-size: 14px !important;
  padding: 5px 20px !important;
  background-color: var(--white-color) !important; 
  color: var(--primary-color);
  font-weight: 500;
}


.footer_modal button.btn-danger{
  border-radius: 5px !important;
  outline: none !important;
  border: 1px solid var(--six-color) !important;
  box-shadow: none !important;
  font-size: 14px !important;
  padding: 5px 20px !important;
  background-color: var(--six-color) !important; 
  color: var(--white-color);
  font-weight: 500;
}

/* update product css start */
.update_products_section{
  background-color: var(--white-color);
  padding: 20px 0px;
}

.product_update_title_div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
  padding: 0px 10px 10px 10px;
}

.product_update_title_div > h1{
  margin-bottom: 0px !important;
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
}

.product_update_title_div > p{
  margin-bottom: 0px !important;
  font-size: 14px;
  font-weight: 500;
}

.product_update_title_div > p > b{
  color: var(--six-color);
  font-weight: 600;

}

.input_main_div_group > label{
  font-size: 14px;
  margin-bottom: 3px;
  font-weight: 500;
  color: var(--black-color3);
}

.input_main_div_group > label > span{
  color: var(--danger-color);
}

.input_main_div_group > input{
  margin-bottom: 12px;
  font-size: 14px;
  border: 1px solid var(--gray-color3) !important;
  border-radius: 4px !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 9px 7px;
}


.input_main_div_group > select{
  width: 100%;
  margin-bottom: 12px;
  font-size: 14px;
  border: 1px solid var(--gray-color3) !important;
  border-radius: 4px !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 9px 7px;
}


.input_main_div_group > textarea{
  margin-bottom: 12px;
  font-size: 14px;
  border: 1px solid var(--gray-color3) !important;
  border-radius: 4px !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 5px 7px;
 
}

.remove_btn_update_prod{
  margin-bottom: 14px;
  background-color: var(--danger-color);
  color: var(--white-color);
  font-size: 12px;
  font-weight: 500;
  border: none !important;
  outline: none !important;
  padding: 5px 10px;
  border-radius: 5px;
}


.add_btn_update_prod{
  margin-bottom: 14px;
  background-color: var(--rating-color2);
  color: var(--white-color);
  font-size: 12px;
  font-weight: 500;
  border: none !important;
  outline: none !important;
  padding: 5px 10px;
  border-radius: 5px;
}

.submit_btn_update_prod{
  margin-bottom: 14px;
  background-color: var(--six-color);
  color: var(--white-color);
  font-size: 13px;
  font-weight: 500;
  border: none !important;
  outline: none !important;
  padding: 5px 15px;
  border-radius: 5px;
}


.description_heading_div_group > h5{
  margin-bottom: 10px !important;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    background-color: var(--gray-color3);
    padding: 6px 10px;
}

.table_update_products{
  overflow-x: auto;
  padding: 20px 0px;
}

.table_update_products th{
  background-color: var(--gray-color3) !important;
}

.table_update_products td{
 border:1px solid var(--gray-color3) !important;
}

.table_update_products td .pending_slct{
  width: 100%;
  margin-bottom: 0px;
  font-size: 12px;
  border: 1px solid var(--gray-color3) !important;
  border-radius: 4px !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 5px 3px;
  background-color: var(--rating-color) !important;
  color: var(--white-color);
}


.table_update_products td .pending_slct option{
  background-color: var(--white-color) !important;
  color: var(--black-color) !important;
}

.table_update_products td .approved_slct{
  width: 100%;
  margin-bottom: 0px;
  font-size: 12px;
  border: 1px solid var(--gray-color3) !important;
  border-radius: 4px !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 5px 3px;
  background-color: var(--rating-color2) !important;
  color: var(--white-color);
}

.table_update_products td .approved_slct option{
  background-color: var(--white-color) !important;
  color: var(--black-color) !important;
}

.table_update_products td {
  font-size: 12px;
  color: var(--black-color6);
  font-weight: 500;
}


/* update product css end */

/*  */
.static_page_update_section{
  padding-bottom: 30px;
}

.static_page_title_div > h1{
  font-size: 18px;
  color: var(--black-color2);
  font-weight: 600;
  margin-bottom: 10px;
}

.tckt_chat_box_main_div strong{
  font-size: 13px !important;
}

.tckt_chat_box_main_div div{
  font-size: 13px;
  font-weight: 500;
}

.update_static_form_main_div label{
font-size: 14px;
margin-bottom: 5px;
text-transform: capitalize;
font-weight: 500;
}

.update_static_form_main_div input{
  border-radius: 4px !important;
  border: 1px solid var(--gray-color2) !important;
  outline: none !important;
  margin-bottom: 7px;
  box-shadow: none !important;
  font-size: 14px;
font-weight: 400;

}

.update_static_form_main_div ::placeholder{
  font-size: 14px;
font-weight: 400;
}

.update_static_form_main_div .toolbar button{
  background-color: var(--gray-color3) !important;
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 5px !important;

}

.update_static_form_main_div .toolbar button.is-active{
  background-color: var(--six-color) !important;
  color: var(--white-color) !important;
}

.update_static_form_main_div .ProseMirror{
  border: 1px solid var(--gray-color2) !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 5px 10px !important;
}

.status_title_static{
font-size: 14px;
margin-bottom: 5px;
text-transform: capitalize;
font-weight: 500;
}

::placeholder{
  font-size: 14px;
font-weight: 400;
}

input{
  border-radius: 4px !important;
  border: 1px solid var(--gray-color2) !important;
  outline: none !important;
  margin-bottom: 7px;
  box-shadow: none !important;
  font-size: 14px;
font-weight: 400;
}

.update_static_form_main_div select{
  width: 100%;
  border-radius: 4px !important;
  border: 1px solid var(--gray-color2) !important;
  outline: none !important;
  margin-bottom: 10px;
  box-shadow: none !important;
  font-size: 14px;
font-weight: 400;
height: 37px;
}

.update_static_form_main_div select option{
  font-size: 14px;
}

.update_static_form_main_div .update_static_btn{
  background-color: var(--six-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  color: var(--white-color) !important;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
}

/*  */

/*  */
.category_modal_div label{
  font-size: 14px !important;
  margin-bottom: 6px;
  font-weight: 500;
  color: var(--black-color2);
}

.category_modal_div label span{
  color: var(--danger-color);
  margin: 0px 0px 0px 4px;
}


.category_modal_div input{
  border-radius: 5px !important;
  outline: none !important;
  box-shadow: none !important;
  height: 35px !important;
  font-size: 13px !important;

}

.category_modal_div ::placeholder{
  font-size: 14px !important;
}

.errormsg{
  font-size: 13px !important;
}

.category_modal_div button.btn-secondary{
background-color: var(--white-color) !important;
border: none !important;
outline: none !important;
color: var(--danger-color) !important;
font-weight: 600 !important;
border: 1px solid var(--danger-color) !important;
border-radius: 5px !important;
font-size: 14px;
margin-top: 8px !important;
}

.category_modal_div button.btn-primary{
  background-color: var(--primary-color) !important;
  border: none !important;
  outline: none !important;
  color: var(--white-color) !important;
  font-weight: 600 !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 5px !important;
  font-size: 14px;
 margin-top: 8px !important;
  }
/*  */

.update_tesimonials_form_div label{
  font-size: 14px !important;
  margin-bottom: 6px;
  font-weight: 500;
  color: var(--black-color2);
}

.update_tesimonials_form_div input{
  border-radius: 5px !important;
  outline: none !important;
  box-shadow: none !important;
  height: 32px !important;
  font-size: 13px !important;
  margin-bottom: 0px !important;
}

.update_tesimonials_form_div svg{
  width: 17px !important;
  margin-top: 4px !important;
}


.update_tesimonials_form_div textarea{
  border-radius: 5px !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: 13px !important;
  border: 1px solid var(--gray-color2) !important;
}


.update_tesimonials_form_div button.btn-secondary{
  background-color: var(--white-color) !important;
  border: none !important;
  outline: none !important;
  color: var(--danger-color) !important;
  font-weight: 600 !important;
  border: 1px solid var(--danger-color) !important;
  border-radius: 5px !important;
  font-size: 14px;
  }
  
  .update_tesimonials_form_div button.btn-primary{
    background-color: var(--primary-color) !important;
    border: none !important;
    outline: none !important;
    color: var(--white-color) !important;
    font-weight: 600 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 5px !important;
    font-size: 14px;
    }


    .Testimonial_ImageDiv{
      text-align: center;
      margin: auto;
      margin-bottom: 5px;
    }

    .Testimonial_ImageDiv img{
      margin: auto;
      text-align: center;
      border-radius: 100px;
      width: 50px;
      height: 50px;
      object-fit: cover;
    }

    .testimonials_user_review_txt{
      text-align: center;
    }

    .social_media_form_div input{
      border-radius: 5px !important;
  outline: none !important;
  box-shadow: none !important;
  height: 32px !important;
  font-size: 13px !important;
  margin-bottom: 0px !important;
    }

    .social_media_form_div ::placeholder{
  font-size: 13px !important;

    }

    .social_media_form_div  img{
      width: 50px !important;
    }

    /*  */

    .ourbrand_update_form_div input{
      margin-bottom: 2px !important;
      font-size: 13px !important;
    }

    .ourbrand_update_form_div ::placeholder{
      font-size: 13px !important;
      
    }

    .ourbrand_update_form_div label{
      font-size: 13px !important;
  margin-bottom: 3px;
  font-weight: 500;
  color: var(--black-color2);
    }

    .signle_online_title  a{
      font-size: 13px !important;
    }

    .ourbrand_update_form_div button.btn-secondary{
      background-color: var(--white-color) !important;
      border: none !important;
      outline: none !important;
      color: var(--danger-color) !important;
      font-weight: 600 !important;
      border: 1px solid var(--danger-color) !important;
      border-radius: 5px !important;
      font-size: 14px;
      }
      
      .ourbrand_update_form_div button.btn-primary{
        background-color: var(--primary-color) !important;
        border: none !important;
        outline: none !important;
        color: var(--white-color) !important;
        font-weight: 600 !important;
        border: 1px solid var(--primary-color) !important;
        border-radius: 5px !important;
        font-size: 14px;
        }

        .admin_filter_ordermngt{
          margin-bottom: 14px;
        }

        .admin_filter_ordermngt select{
          width: 100%;
          border-radius: 3px !important;
          border: 1px solid var(--gray-color2) !important;
          height: 30px;
          outline: none !important;
          box-shadow: none !important;
          margin-top: 6px;
        }

        .admin_filter_ordermngt label{
          font-size: 14px !important;
          margin-bottom: 6px;
          font-weight: 500;
          color: var(--black-color2);
        }


        
/*  */

.cancel_modal_main_div{
  text-align: center;
}

.cancel_modal_main_div .modal-body{
  padding: 20px 20px 10px 20px!important;
}

.cancel_modal_main_div_message > img{
  width: 80px;
  margin-bottom: 10px;
}

.cancel_modal_main_div_message > h2{
  font-size: 18px;
  margin-top: 5px;
  font-weight: 700;
  color: var(--black-color3);
}

.cancel_modal_main_div_message > h1{
  font-size: 16px;
  font-weight: 600;
  color: var(--black-color5);
}

.cancel_modal_main_div .modal-footer{
  display: unset !important;
  align-items: center !important;
  border: none !important;
  padding: 15px 20px 20px 20px !important;
  border-top: 1px dashed var(--gray-color2) !important;
}

.cancel_modal_main_div .modal-footer .goback_btn{
  background-color: var(--white-color) !important;
  border: 1px solid var(--gray-color3) !important;
  border-radius: 100px !important;
  color: var(--black-color3) !important;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 20px !important;
}

.cancel_modal_main_div .modal-footer .goback_btn:hover{
  background-color: var(--six-color) !important;
  color: var(--white-color) !important;
}


.cancel_modal_main_div .modal-footer .cancel_btn{
  background-color: var(--danger-color) !important;
  border: 1px solid var(--danger-color3) !important;
  border-radius: 100px !important;
  color: var(--white-color) !important;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 20px !important;
}


.cancel_modal_main_div .modal-footer .cancel_btn:hover{
  background-color: var(--danger-color) !important;
  color: var(--white-color) !important;
}

.cancel_order_div_box{
  text-align: center;
  background-color: var(--white-color);
  padding: 15px 0px 0px 0px;
  margin-bottom: 20px;
  border-radius: 6px;
    border: 1px solid var(--gray-color3);
}


.cancel_order_img_box > img{
  width: 70px;
}


.cancel_order_img_box > h2{
  color: var(--danger-color);
  font-size: 20px;
  font-weight: 700;
  margin-top: 4px;
}

.cancel_order_div_box > a{
  background-color: var(--gray-color3);
  display: block;
  padding: 6px 5px;
  font-size: 14px;
  font-weight: 700;
}
    
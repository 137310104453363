
/* chat_box_sectionwh */

.tckt_chat_box{
    background-color: #f1f3f6;
    padding: 14px 0px 14px 0px;
}

.fixed_main_div{
    position: relative;
    overflow: hidden;
  }
  
  .chat-footer{
    position: fixed;
    bottom: 0;
  }
  
  .tckt_chat_box_main_div {
    width: 100%;
    max-width: 100%;
    background-color: var(--white-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    height: 400px;
    max-height: 430px;
    overflow-y: auto;
    padding: 10px 20px;
  }
  
  .tckt_msg_recevied_main_div{
    margin: 5px;
    display: flex;
  }
  
  .receviedmsg_txt {
    max-width: 50%;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .receviedmsg_img img{
    width: 200px;
    border-radius: 4px;
    border: 4px solid var(--gray-color3);
    object-fit: contain;
  }
  
  .tckt_msg_recevied_main_div {
    justify-content: flex-start;
  }
  
  .receviedmsg_txt {
    background-color: var(--gray-color3);
    border-bottom-left-radius: 0;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    color: var(--black-color5);
  }
  
  .tckt_msg_send_main_div {
    margin: 5px;
    display: flex;
  }
  
  .sendmsg_txt {
    max-width: 50%;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .sendmsg_img img{
    width: 200px;
    border-radius: 4px;
    border: 4px solid var(--six-color);
    object-fit: contain;
  }
  
  .tckt_msg_send_main_div {
    justify-content: flex-end;
  }
  .sendmsg_txt {
    background-color: var(--six-color);
    border-bottom-right-radius: 0;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--white-color);
    font-size: 14px;
  }
  
  
  .chat-footer {
    display: flex;
    padding: 10px 10px;
    background-color: var(--gray-color3);
    position: absolute;
    bottom: 0;
    width: 100%;
    align-items: center;
    gap: 10px;
  
  }
  
  .chat-footer input {
    flex: 1;
    padding: 10px;
    border-radius: 0px !important;
    margin-right: 0px;
    border-radius: 0px !important;
    box-shadow: none !important;
    outline: none !important;
   border: 1px solid var(--gray-color) !important;
  }

  .image_upload_input{
    display: none;
  }

  .image_upload_label{
    cursor: pointer;
  }

  .image_upload_label > img{
    width: 45px;
  }

  .chat-footer button {
    padding: 10px 30px;
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
    border: none;
    border-radius: 2px !important;
    cursor: pointer;
  }
  
  
   /* width */
   .tckt_chat_box_main_div::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
     .tckt_chat_box_main_div:hover::-webkit-scrollbar-track {
    background-color: var(--white-color);
  }
  
  /* Handle */
     .tckt_chat_box_main_div:hover::-webkit-scrollbar-thumb {
    background-color: var(--third-color); 
    border-radius: 100px;
  }
  
  /* Handle on hover */
     .tckt_chat_box_main_div:hover::-webkit-scrollbar-thumb:hover {
    background: var(--six-color) !important; 
  }
  
  /*  */
  
  /* chat_box_sectionwh */
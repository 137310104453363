.table_user_data Table thead tr th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 14px;
  text-transform: capitalize;
}

.table_user_data Table tbody tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 12px;
  max-width: 300px;
}

.table_user_data > table > tbody > tr > td > img{
  object-fit: contain;
  text-align: center;
  margin: auto;
}

.products_review > b{
  background-color: var(--rating-color) !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 12px;
  padding: 5px 10px 5px 5px!important;
  color: var(--white-color) !important;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 10px;
}

.toolbar button {
  padding: 6px 12px;
  border: none;
  background-color: #e0e0e0;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.toolbar button:hover {
  background-color: #d1d1d1;
}

.toolbar button.is-active {
  background-color: #007bff;
  color: white;
}

.ProseMirror {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 150px; /* Adjust height as needed */
  border-radius: 5px;
  outline: none; /* Remove default focus outline */
}

.ProseMirror:focus {
  border-color: #007bff; /* Highlight border when focused */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
